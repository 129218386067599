import { useState } from "react";
import { useEffect } from "react";
import "./App.css";
import Popup from "./Popup";
import waLogo from "./Assets/walogo.webp";
import UnsubPopup from "./UnsubPopup";
import { Toaster } from "react-hot-toast";

export const apiUrl = "https://waback.sense-apps.com";
// export const apiUrl = "http://localhost:3031";

function App() {
  const [settingsFromServer, setSettingsFromServer] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const lang = queryParams.get("lang");

  useEffect(() => {
    document.title = `${
      settingsFromServer?.customTitle && settingsFromServer.customTitle !== ""
        ? settingsFromServer.customTitle
        : "Sense WhatsApp Landing Page"
    }`;
  }, [settingsFromServer]);

  const [unsubscribe, setUnsubscribe] = useState(false);

  useEffect(() => {
    fetch(`${apiUrl}/blockcodes/${code}`)
      .then((response) => response.json())
      .then((data) => setSettingsFromServer(data));
  }, []);

  const SupportClick = () => {
    window.open(`https://wa.me/${settingsFromServer.phone}`, "_blank");
  };

  const RemoveFromShopClick = () => setUnsubscribe(true);

  const ShopWebsiteClick = () => {
    window.open(
      settingsFromServer.customLink || "https://" + settingsFromServer.shop,
      "_blank"
    );
  };

  const ShareClick = () => {
    navigator.share({
      title: "web.dev",
      text: "Check out web.dev.",
      url: settingsFromServer.shop,
    });
  };

  console.log({ settingsFromServer });

  const Contact = () => {
    // tel link
    window.open(`tel:${settingsFromServer.phone}`, "_blank");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      <Toaster
        toastOptions={{
          style: {
            direction: lang == "he" ? "rtl" : "ltr",
          },
        }}
      />
      <img
        src={settingsFromServer.imgUrl ?? waLogo}
        style={{ maxWidth: 400 }}
      />
      {lang == "he" ? (
        <div>
          <p className="Title">
            לקוח יקר, השתמש באפשרויות הבאות כדי לבצע פעולות הרלוונטיות למערכת
            ה-WhatsApp של חנות{" "}
            {settingsFromServer.customName || settingsFromServer.shop}.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
              alignSelf: "center",
            }}
          >
            {settingsFromServer.phone && (
              <button onClick={SupportClick} className="button">
                שירות לקוחות ב-WhatsApp
              </button>
            )}
            <button className="button" onClick={RemoveFromShopClick}>
              הסרה מרשימה התפוצה
            </button>
            <button className="button" onClick={ShopWebsiteClick}>
              כניסה לחנות באינטרנט
            </button>
            <button className="button" onClick={Contact}>
              צור קשר
            </button>
            <button className="button" onClick={ShareClick}>
              שיתוף החנות
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p className="TitleEng">
            Dear customer, use the following options to perform actions relevant
            to the WhatsApp system of the {settingsFromServer.shop} store.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
            }}
          >
            {settingsFromServer.phone && (
              <button className="button" onClick={SupportClick}>
                Customer service on WhatsApp
              </button>
            )}
            <button className="button" onClick={RemoveFromShopClick}>
              Unsubscribe
            </button>
            <button className="button" onClick={ShopWebsiteClick}>
              Visit our store
            </button>
            <button className="button" onClick={Contact}>
              Contact us
            </button>
            <button className="button" onClick={ShareClick}>
              Share
            </button>
          </div>
        </div>
      )}

      <Popup isOpen={isOpen} setIsOpen={setIsOpen} />

      <UnsubPopup
        isOpen={unsubscribe}
        setIsOpen={setUnsubscribe}
        code={code}
        lang={lang}
      />
    </div>
  );
}

function validatePhone(value) {
  console.log(value, "value", value);
  var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (value.replace("+", "").match(phoneno)) {
    return true;
  } else {
    alert("message");
    return false;
  }
}

export default App;
