import toast from "react-hot-toast";
import { apiUrl } from "./App";

export default function UnsubPopup({ isOpen, setIsOpen, code, lang }) {
  const RemoveFromShopClick = () => {
    setIsOpen(true);
    const settings = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ code }),
    };

    fetch(`${apiUrl}/blockcodes/`, settings).then((response) => {
      if (response.status != 200) {
        toast.error(lang == "he" ? "חלה שגיאה!" : "Something went wrong");
      } else {
        setIsOpen(false);
        toast.success(
          lang == "he"
            ? "הסרתם את עצמכם מרשימת התפוצה של החנות"
            : "You have unsubscribed from this shop"
        );
      }
    });
  };

  return (
    <div
      onClick={() => setIsOpen(false)}
      className={
        isOpen ? "wa-popup-container open" : "wa-popup-container close"
      }
    >
      <div
        onClick={(ev) => ev.stopPropagation()}
        className="wa-popup"
        style={{
          direction: lang == "he" ? "rtl" : "ltr",
        }}
      >
        <div className="popup-header">
          <span>{lang == "he" ? "הסרה מרשימת התפוצה" : "Unsubscribe"}</span>
          <div className="close-popup" onClick={() => setIsOpen(false)}>
            x
          </div>
        </div>
        <div className="popup-body">
          {lang == "he"
            ? "האם אתם בטוחים שאתם רוצים להסיר את עצמכם מרשימת התפוצה של חנות זו?"
            : "Are you sure you want to unsubscribe from this shop?"}
        </div>
        <div className="popup-footer flex align-center justify-center">
          <button
            className="button-secondary same"
            onClick={() => setIsOpen(false)}
          >
            {lang == "he" ? "לא" : "No"}
          </button>
          <button
            className="button-secondary same"
            onClick={RemoveFromShopClick}
          >
            {lang == "he" ? "כן" : "Yes"}
          </button>
        </div>
      </div>
    </div>
  );
}
