import { useState } from "react";

export default function Popup({ isOpen, setIsOpen }) {
    // const [isOpen, setIsOpen] = useState(false);

    return (
        <div onClick={() => setIsOpen(false)} className={isOpen ? 'wa-popup-container open' : 'wa-popup-container close'}>
            <div onClick={(ev) => ev.stopPropagation()} className='wa-popup'>
                <div className='popup-header'>
                    <span>Are you sure</span>
                    <div className='close-popup' onClick={() => setIsOpen(false)} >
                        x
                    </div>
                </div>
                <div className='popup-body'>
                    Are you sure you want to delete this scheduled message
                </div>
                <div className='popup-footer flex align-center justify-center'>
                    <button className='button-secondary same' onClick={() => setIsOpen(false)} >Close</button>
                </div>
            </div>
        </div>
    )
}